import { css } from 'astroturf';
import React, { useState } from 'react';

import Logo from '../../../assets/images/icon.svg';
import ListItem from './ListItem';
import RequestQuote from './RequestQuote';

const styles = css`
  .price {
    overflow: hidden;
    background: #fff;
    position: relative;
    margin: 1rem;
    box-shadow:
      rgba(0, 0, 0, 0.025) 0 0 0 1px,
      rgba(0, 0, 0, 0.05) 0px 1px 0px,
      rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 20px 30px;
    padding: 2rem 1.5rem 2rem;
    border-radius: 0.5rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    min-width: 280px;
    max-width: 280px;

    @media (min-width: 75.01rem) {
      margin: 0;
    }
    &:nth-child(3) {
      & .banner {
        background: #107db5;
        top: -3.4rem;
        right: -7.9rem;
      }
    }

    &:nth-child(2) {
      & h3 {
        color: #4da758;
      }

      & .topbar,
      & .intro::before {
        background: #67b26f;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      & .button {
        background: #107db5 linear-gradient(135deg, #107db5, #107db5);
      }
    }

    &:nth-child(1) {
      order: 4;
      margin-top: 1rem;

      @media (min-width: 1201px) {
        order: 0;
        margin-top: 0;
        margin-right: auto;
        & .intro {
          margin-bottom: 0;
        }
      }

      & .button {
        background: #fff;
        color: #107db5;
        border: 2px solid;
        transition: 0.25s ease-in-out color;

        &::before {
          display: none;
        }

        &:hover,
        &:focus {
          color: #000;
        }
      }
      & .intro {
        margin-bottom: 0;
        &::before {
          background: white;
        }
      }

      & .row {
        display: none;
      }

      @media (min-width: 905px) and (max-width: 1200px) {
        max-width: 56.5rem;
        width: calc(100% - 0rem);
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        gap: 2rem;

        & .intro {
          order: 0;

          & svg {
            display: none;
          }
        }

        & .freetrialinfo {
          display: none;
        }

        & ul {
          order: 0;
        }
        & .buttonWrap {
          flex: 1 1 100px;
          max-width: 220px;
          order: 2;
          margin: 0;

          align-self: flex-end;
        }
      }
    }
    & > svg {
      align-self: flex-end;
    }

    & .topbar {
      background: #107db5;
      font-weight: bold;
      color: #fff;
      text-align: left;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: 1.5rem;
    }

    & .intro {
      position: relative;
      text-align: left;
      margin-bottom: 3rem;

      & h3 {
        font-weight: 900;
        font-size: clamp(18px, calc(18px + 0.6vw), 26px);
      }

      &::before {
        z-index: 0;
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 50rem;
        height: 30rem;
        margin-left: -25rem;
        margin-top: -26rem;
        border-radius: 100%;
        background: #4ca2cd;
        opacity: 0.075;
        pointer-events: none;
      }

      & p {
        max-width: 13rem;
        font-size: 0.875rem;
      }

      & svg {
        display: block;
        margin: 1.5rem auto 1.35rem;
        width: 5rem;
        height: 5rem;
      }
    }
    & .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 1rem;
      text-align: left;
    }

    & .bottomSmall {
      opacity: 0.75;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0.5rem;
      text-align: center;
      padding: 0 2rem;
      height: 3rem;
    }

    & .inbetween {
      margin: 0;
      text-align: left;
      color: #767676;

      & span {
        font-variant: small-caps;
      }
    }

    & h3 {
      color: #107db5;
      font-size: 2rem;
    }

    & h4 {
      text-align: left;
      margin: 0;
      line-height: 1;
      font-size: 1.5rem;
      text-align: left;
      font-weight: 700;
      color: #113;

      & > span {
        position: relative;
        display: inline-block;
        text-align: left;
        height: 1.5rem;
        vertical-align: bottom;
        overflow: hidden;
        white-space: nowrap;

        &.showYearly {
          & > span {
            transform: translateY(0px);
          }
        }
        & > span {
          display: block;
          transition: 0.3s ease-out transform;
          transform: translateY(-1.5rem);

          & span {
          }
        }
      }

      & small {
        color: #667;
        font-size: 0.75rem;
        font-weight: 400;
        display: inline-block;
        margin-left: 0.25rem;
        margin-right: 0.5rem;
        & span {
          transition: 0.25s ease-in-out opacity;
          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }

    & p {
      color: #767676;
      line-height: 1.25;
      margin-bottom: 0;
      transition: 0.25s ease-in-out opacity;
      opacity: 1;

      &.hidden {
        opacity: 0;
        pointer-events: none;
        transition: 0s ease-in-out opacity;
      }

      & strong {
        color: #333338;
        font-weight: 400;
      }

      & small {
        font-size: 0.75rem;
      }

      & ins {
        text-decoration: none;
      }
    }

    & ul {
      max-width: 210px;
      list-style: none;
      margin: 0 0 0rem 1.5rem;
      & li {
        position: relative;
        font-size: 0.875rem;
        white-space: nowrap;

        & + li {
          margin-top: 0.5rem;
        }
      }

      & > li > svg:first-child {
        margin-left: 0;
        position: absolute;
        left: -22px;
        top: 4px;
        background: #67b26f;
        stroke: #fff;
        border-radius: 100%;
        padding: 2px;
        vertical-align: text-bottom;
      }

      & > li > span svg {
        margin-left: 5px;
        vertical-align: text-bottom;
      }
    }

    & .buttonWrap {
      display: flex;
      flex-direction: column;
      border-radius: 2rem;
      margin: 0;

      & small {
        display: block;
        text-align: center;
        opacity: 1;
        transform: translateX(0);
        font-size: 0.875rem;
        margin: 1rem auto 0;
        line-height: 1rem;
        max-width: 14rem;
      }
    }

    & .button {
      position: relative;
      background: #008a00 linear-gradient(135deg, #008a00, #008a00);
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      border: 0;
      border-radius: 2.5rem;
      width: 100%;
      height: 2.5rem;
      transition: background 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;
      max-width: 232px;
      margin-left: auto;
      margin-right: auto;

      display: grid;
      place-items: center;

      &:hocus {
        &::before {
          opacity: 1;
        }
      }

      & span {
        position: relative;
        z-index: 1;
      }

      &::before {
        will-change: opacity;
        content: ' ';
        display: block;
        background: linear-gradient(135deg, #4ca2cd, #67b26f);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        border-radius: 7px;
      }
    }
  }

  .banner,
  .banner2 {
    text-align: center;
    background: #008a00;
    color: #fff;
    padding: 0 5rem;
    font-weight: bold;
    height: 1.5rem;
    line-height: 1.5rem;
    position: absolute;
    top: -3.1rem;
    right: -8rem;
    transform-origin: 0 0;
    transform: rotate(45deg);
    transition: opacity 0.25s ease-in-out;
    display: block;
    will-change: opacity;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .banner2 {
    background: rgb(16, 125, 181);
    right: -9.3rem;
    top: -3.6rem;
    box-shadow:
      rgba(0, 0, 0, 0.025) 0 0 0 1px,
      rgba(0, 0, 0, 0.05) 0px 1px 0px,
      rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 0px 30px;
  }

  .toggle {
    border: none;
    background: none;
    text-align: left;
    text-decoration: underline;
    color: #67b26f;
    width: 100%;
    cursor: pointer;
    font-size: 0.75rem;

    &:hocus {
      color: #107db5;
    }
  }
  .freetrialinfo {
    opacity: 0.8;
    text-align: center;
    margin-bottom: -1.2rem;
  }

  .annualcta {
    color: #107db5;
  }
`;

const Column = (props) => {
  const [requestQuoteVisible, setRequestQuoteVisible] = useState(false);
  const { priceInfo, priceYearly, localPrice, setSignUpVisible, highlighted } = props;
  const { id } = priceInfo[priceYearly ? 0 : 1];

  const openCheckout = (e) => {
    e.preventDefault();
    if (props.name === 'Free trial') {
      location.href = 'https://dashboard.polypane.app/register/?ref=pricingtable';
    } else if (id) {
      setSignUpVisible({
        id,
        visible: true,
        name: props.name,
        priceYearly,
        total: localPrice,
        priceInfo: priceInfo[priceYearly ? 0 : 1],
        sub: props.name !== 'Individual' ? 'pro' : 'team',
      });

      if (typeof window !== 'undefined' && window.ga) {
        window.ga('send', {
          hitType: 'pageview',
          title: 'Signup form',
          location: `${window.location.href}${window.location.href.endsWith('/') ? '' : '/'}signup-form`,
          page: `${window.location.pathname}${window.location.href.endsWith('/') ? '' : '/'}signup-form`,
        });
      }
    } else {
      setRequestQuoteVisible(true);
      if (typeof window !== 'undefined' && window.ga) {
        window.ga('send', {
          hitType: 'pageview',
          title: 'Request quote form',
          location: `${window.location.href}${window.location.href.endsWith('/') ? '' : '/'}request-quote-form`,
          page: `${window.location.pathname}${window.location.href.endsWith('/') ? '' : '/'}request-quote-form`,
        });
      }
    }
  };

  const togglePricing = (e) => {
    e.preventDefault();
    props.togglePricing(!props.priceYearly);
  };

  return (
    <div className={styles.price}>
      {highlighted ? <small className={styles.banner}>Current trial plan</small> : null}
      {props.freeTrial ? <small className={styles.banner2}>✨ No account? Start here!</small> : null}
      <div className={styles.intro}>
        <h3>{props.name}</h3>
        <p>
          <strong>{props.desc}</strong>
        </p>
        {props.name === 'Free trial' ? <Logo /> : null}
      </div>

      <div className={styles.row}>
        <h4>
          {props.name === 'Enterprise' ? (
            <span>{priceInfo[0].price}</span>
          ) : props.name === 'Free trial' ? (
            <>0</>
          ) : (
            <span className={priceYearly ? styles.showYearly : ''}>
              <span>
                {priceInfo
                  .filter((x) => x.price.trim())
                  .map((item, index) => (
                    <React.Fragment key={item.price}>
                      <span aria-hidden={(priceYearly && index === 1) || (!priceYearly && index === 0)}>
                        {item.price}
                      </span>
                      <br />
                    </React.Fragment>
                  ))}
              </span>
            </span>
          )}

          {props.name === 'Enterprise' ? (
            <small></small>
          ) : props.name === 'Free trial' ? (
            <></>
          ) : (
            <small>
              / month
              <span className={priceYearly ? styles.hidden : ''}>
                {priceInfo[priceYearly ? 0 : 1].hasVat ? ' incl. tax' : ''}
              </span>
            </small>
          )}
        </h4>
        {props.name === 'Enterprise' ? (
          <p>
            <small>&nbsp;</small>
          </p>
        ) : (
          <p className={props.name !== 'Free trial' ? '' : styles.hidden}>
            <small>
              {priceYearly ? (
                <>
                  <b>Billed at {props.localPrice}/year</b>
                  {priceInfo[priceYearly ? 0 : 1].hasVat ? ' incl. tax' : ''}
                </>
              ) : (
                <span className={styles.annualcta}>
                  Go yearly to get <b>3 months free</b>
                </span>
              )}
            </small>
          </p>
        )}
        {props.name === 'Enterprise' || props.name === 'Free trial' ? null : (
          <>
            {/* <button className={styles.toggle} onClick={togglePricing}>
              Switch to {priceYearly ? 'monthly' : 'yearly'}
            </button> */}
          </>
        )}
      </div>

      <div className={styles.buttonWrap}>
        <button type="button" className={styles.button} onClick={openCheckout} id={props.buttonID}>
          <span>{props.buttonText}</span>
        </button>
      </div>

      {props.name === 'Free trial' ? <small className={styles.freetrialinfo}>No credit card needed.</small> : ''}
      <div style={{ height: '2rem' }} />
      <ul>
        {props.benefits.map((benefit, index) => (
          <ListItem key={benefit} tip={(props.tooltips && props.tooltips[index]) || false}>
            {benefit}
          </ListItem>
        ))}
      </ul>
      {!id && <RequestQuote visible={requestQuoteVisible} setVisible={setRequestQuoteVisible} />}
    </div>
  );
};

export default Column;
