import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React, { Component } from 'react';
import { Close } from 'react-bytesize-icons';
import SignUpCanceled from './SignUpCanceled';

// const CHECK_URL = `http://localhost:3000/api/chksrxsts`;
const CHECK_URL = `https://dashboard.polypane.app/api/chksrxsts`;

const styles = css`
  .wrap {
    position: fixed;
    background: linear-gradient(135deg, #0c628d, ease-in-out, #67b26f);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    z-index: 100;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s ease-in-out all;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transform: translateZ(1001px);
  }
  .visible {
    opacity: 1;
    pointer-events: all;
  }
  .hidden {
    height: 0;
    overflow: hidden;
  }

  .formHidden,
  .contactForm {
    position: relative;
    margin: 0 auto;
    padding: 2rem 1rem 1rem;
    width: 100%;
    background: #fff;
    text-align: left;

    height: 100%;
    max-height: 100%;
    overflow: auto;
    max-width: 30rem;
    margin: 0;
    box-shadow:
      rgba(0, 0, 0, 0.025) 0 0 0 1px,
      0 1px 10px rgba(0, 0, 0, 0.05),
      0 5px 10px rgba(0, 0, 0, 0.06);

    & h4 {
      padding: 2rem 1rem 1rem;
      color: #107db5;
      font-size: 1.25em;
      line-height: 1;
      font-weight: 600;
    }

    & > div {
      position: relative;
      max-width: 40rem;
      width: 100%;
      margin: 0 auto;
      padding: 0 1rem;

      &.paddleArea {
        padding: 1rem 0 0;
        min-height: 40px;
        z-index: 1;

        &.hidden {
          padding: 0;
          min-height: 0;
        }
        & iframe {
          overflow: hidden;
        }
      }

      &.error {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 180px;

        & > * {
          padding: 0 1rem;
        }
      }

      &.noaccount {
        & p {
          margin: 1rem 0;
        }

        & strong strong {
          font-weight: normal;
        }

        & div {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        & a {
          position: relative;
          text-align: center;
          font-weight: 600;
          font-size: 1rem;
          border: 0;
          border-radius: 0.3rem;
          margin: 0 1rem 0 0;
          height: 2.5rem;
          line-height: 2rem;
          overflow: hidden;
          background: none;
          box-shadow: none;
          text-decoration: underline;
          flex: 0 1 180px;

          &:hocus {
            color: #000;
          }
        }
      }

      & .error {
        margin: 1rem 0;
        text-align: center;
        font-weight: bold;
      }
    }
    & > div > * {
      display: block;
    }

    & .formTitle {
      display: flex;
      align-items: flex-start;

      & img {
        margin-right: 1rem;
        display: none;
        max-width: 64px;
        @media screen and (min-width: 50rem) {
          display: block;
        }
      }

      & p {
        font-size: 1rem;
        margin: 0.25rem 0 0;
        font-weight: normal;
      }

      & .ident {
        opacity: 0.8;
      }
    }

    & h3,
    & legend {
      font-size: 1.25rem;
      color: #107db5;
      font-weight: 600;
      width: 100%;
      line-height: 1;
      margin: 0;
      padding: 0;

      & small {
        display: block;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5;
      }
    }
    & p {
      margin: 1rem 0;
      line-height: 1.5;
    }

    & label {
      text-align: left;
      margin: 0.75rem 0 0.25rem;
      font-weight: bold;
    }

    & input,
    & textarea {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      width: 100%;
      box-shadow: rgba(210, 212, 222, 0.85) 0px 0.5px 1.5px;
      background: rgb(255, 255, 255);
      border-color: rgb(204, 204, 204);
      border-width: 1px;
      border-style: solid;
      border-radius: 0.3rem;
    }

    & select {
      font-size: 1rem;
      padding: 0.5rem 0.8rem;
      width: 100%;
      box-shadow: rgba(210, 212, 222, 0.85) 0px 0.5px 1.5px;
      background: rgb(255, 255, 255);
      border-color: rgb(204, 204, 204);
      border-width: 1px;
      border-style: solid;
      border-radius: 0.3rem;
    }

    & label input {
      width: auto;
    }
    & textarea {
      height: 6rem;
    }

    & .topMargin {
      margin-top: 1rem;
      font-weight: normal;
    }

    & div button {
      position: relative;
      background: linear-gradient(135deg, #67b26f, #67b26f);
      text-align: center;
      font-weight: 600;
      font-size: 1.2rem;
      color: #fff;
      border: 0;
      border-radius: 0.3rem;
      width: 100%;
      min-width: 175px;
      margin: 1rem auto;
      height: 2.5rem;
      transition: background 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;

      &:hocus {
        &::before {
          opacity: 1;
        }
      }

      & span {
        position: relative;
        z-index: 1;
      }

      &::before {
        content: ' ';
        display: block;
        background: linear-gradient(135deg, #4ca2cd, #67b26f);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        border-radius: 0.5rem;
      }
    }

    & small {
      display: block;
      & svg {
        vertical-align: top;
      }
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: 0;
    color: #107db5;
    padding: 1rem;
    opacity: 0.5;
    transition: 0.25s ease-in-out opacity;

    &:hocus {
      opacity: 1;
    }
  }

  .checkoutContainer {
    padding: 0 0.25rem;
    display: grid;
    grid-template-columns: 1fr;

    & > div {
      grid-row: 1/2;
      grid-column: 1/2;

      &[hidden] {
        display: none;
      }
    }
  }

  .badge {
    vertical-align: middle;
    font-size: 0.8rem;
    line-height: 0.8rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
  }
`;

class signUp extends Component {
  constructor(props) {
    super();

    this.state = {
      message: '',
      status: '',
      closedCallbackVisible: false,
      personData: {},
      showForm: true,
      showTrialMessage: false,
      subscriptionInfo: { country: props.country },
      separateBilling: false,
    };

    this.firstInput;
  }

  async componentDidMount() {
    const params = new URL(document.location).searchParams;

    if (params.get('email')) {
      const getData = await fetch(CHECK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: params.get('email'), hash: params.get('hash') }),
      }).then((response) => response.json());

      const subscriptionInfo = {
        ...this.state.subscriptionInfo,
        canSubscribe: getData.canSubscribe,
        exist: getData.exists,
        givenname: getData.name,
        email: params.get('email'),
        emp: params.get('emp'),
        country: this.props.country,
      };

      if (getData.canSubscribe) {
        this.setState({
          subscriptionInfo,
          personData: { email: params.get('email'), givenname: getData?.name || '' },
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.firstInput && this.firstInput.focus();

      document.body.style.overflow = 'hidden';
    }

    if (!this.props.visible && prevProps.visible) {
      document.body.style.overflow = 'auto';
    }

    if (this.props.country !== prevProps.country) {
      this.setState((state) => ({
        subscriptionInfo: { ...state.subscriptionInfo, country: this.props.country },
      }));
    }
  }

  openPaddleCheckout = (personData) => {
    const ref = this;

    const { email, givenname, billingemail } = personData;
    const { Paddle } = window;
    const paddleSettings = {
      email: billingemail || email,
      method: 'inline',
      frameTarget: 'checkout-container',
      frameInitialHeight: 337,
      frameStyle: 'position:relative !important;width:100%; background-color: #fff; border: none;',
      marketingConsent: 1,
      product: this.props.id,
      quantity: 1,
      quantity_variable: 0,
      allowQuantity: false,
      disableLogout: true,
      success: '/thanks',
      passthrough: JSON.stringify({
        givenname,
        email,
        ...(this.props.passThrough || {}),
      }),
      coupon: ref.props.coupon,
      country: this.state.subscriptionInfo.country,
      postcode: this.state.subscriptionInfo.postcode,
      customData: {
        tolt_referral: window.tolt_referral,
      },
    };

    if (this.props.customURL) {
      paddleSettings.override = this.props.customURL;
    }

    Paddle.Checkout.open(paddleSettings);
    this.setState({ showForm: false, showTrialMessage: false });

    setTimeout(() => {
      this.error.removeAttribute('hidden');
    }, 2000);

    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', {
        hitType: 'pageview',
        title: 'Paddle Checkout',
        location: `${window.location.href}${window.location.href.endsWith('/') ? '' : '/'}paddle-checkout`,
        page: `${window.location.pathname}${window.location.href.endsWith('/') ? '' : '/'}paddle-checkout`,
      });
    }
  };

  setSeparateBilling = (e) => {
    this.setState({ separateBilling: e.target.checked });
  };

  updateGivenName = (e) => {
    this.setState((state) => ({
      subscriptionInfo: { ...state.subscriptionInfo, givenname: e.target.value },
    }));
  };

  updateEmail = (e) => {
    this.setState((state) => ({
      subscriptionInfo: { ...state.subscriptionInfo, email: e.target.value },
    }));
  };

  updateCountry = (e) => {
    this.setState((state) => ({
      subscriptionInfo: { ...state.subscriptionInfo, country: e.target.value },
    }));
  };

  updateZipCode = (e) => {
    this.setState((state) => ({
      subscriptionInfo: { ...state.subscriptionInfo, postcode: e.target.value },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const givenname = e.target.elements[0].value;
    const email = e.target.elements[1].value.toLowerCase();
    const billingemail = e.target.elements[2].checked ? e.target.elements[3].value : false;
    const { country, postcode } = this.state.subscriptionInfo;

    if (email && givenname) {
      const emailAllowed = await fetch(CHECK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .catch(() => ({ exists: true, message: "Can't locate validation server, try again later." }));

      this.setState((state) => ({
        personData: { email, givenname, billingemail },
        subscriptionInfo: { ...state.subscriptionInfo, email, givenname },
      }));
      if (!emailAllowed.exists && !this.props.passTrialMessage) {
        this.setState({ showTrialMessage: true });
      } else if (emailAllowed.canSubscribe) {
        this.openPaddleCheckout({ email, givenname, billingemail, country, postcode });
      } else {
        this.setState({
          status: 'error',
          message: `${emailAllowed.message}`,
        });
      }
    } else {
      this.setState({
        status: 'error',
        message: `All form fields are required`,
      });
    }
  };

  resetForm = () => {
    this.setState({
      status: '',
      message: '',
      showForm: true,
      showTrialMessage: false,
    });

    this.error.setAttribute('hidden', true);
  };

  closeForm = () => {
    this.props.setSignUpVisible({
      id: this.props.id,
      visible: false,
      name: this.props.name,
      priceYearly: this.props.priceYearly,
      total: this.propslocalPrice,
      priceInfo: this.props.priceInfo,
    });

    setTimeout(() => {
      this.resetForm();
    }, 500);
  };

  hideForm = () => {
    if (this.state.showForm) {
      this.closeForm();
    } else {
      this.setClosedCallbackVisible(true);

      if (typeof window !== 'undefined' && window.ga) {
        window.ga('send', {
          hitType: 'pageview',
          title: 'Checkout closed',
          location: `${window.location.href}${window.location.href.endsWith('/') ? '' : '/'}checkout-closed`,
          page: `${window.location.pathname}${window.location.href.endsWith('/') ? '' : '/'}checkout-closed`,
        });
      }
    }
  };

  setClosedCallbackVisible = (value) => {
    this.setState({ closedCallbackVisible: value });
  };

  restartCheckout = () => {
    this.setClosedCallbackVisible(false);
  };

  continueSubscribing = (e) => {
    e.preventDefault();
    this.openPaddleCheckout(this.state.personData);
  };

  render() {
    const { visible, name, priceYearly, total, checkoutprice, priceInfo } = this.props;
    const { status, message, subscriptionInfo, showTrialMessage } = this.state;

    return (
      <StaticQuery
        query={detailsQuery}
        render={(data) => (
          <>
            <div className={[styles.wrap, visible && styles.visible].join(' ')} aria-hidden={!visible}>
              <form className={styles.contactForm} onSubmit={this.handleSubmit}>
                <div className={styles.formTitle}>
                  <img src={data.iconSVG.publicURL} alt="" title="" />
                  <div>
                    <small>Order details</small>
                    <h3>Polypane {name}</h3>
                    <span className={styles.ident}>
                      {subscriptionInfo.email ? <>for {subscriptionInfo.email}</> : null}&nbsp;
                    </span>

                    {checkoutprice && checkoutprice !== total ? <p>This checkout: {checkoutprice}</p> : null}
                    <p>
                      {checkoutprice && checkoutprice !== total ? (
                        <>After the first {priceYearly ? 'year' : 'month'}</>
                      ) : null}{' '}
                      {total} billed {priceYearly ? ' yearly ' : ' monthly '}
                      {priceInfo.hasVat ? <>(includes {priceInfo.vat} VAT)</> : <></>} <br />
                    </p>
                  </div>
                </div>

                {showTrialMessage ? (
                  <div className={styles.noaccount}>
                    <p>
                      <strong>
                        The email <strong>{this.state.personData.email}</strong> has no account.
                      </strong>
                    </p>
                    <p>
                      You can start a free 14-day trial and subscribe later, or subscribe right now without a trial.
                    </p>
                    <div>
                      <a
                        href={`https://dashboard.polypane.app/register?email=${encodeURIComponent(
                          this.state.personData.email
                        )}&name=${encodeURIComponent(encodeURIComponent(this.state.personData.givenname))}`}
                      >
                        <span>Start free trial</span>
                      </a>
                      <button type="button" onClick={this.continueSubscribing}>
                        <span>Continue</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.showForm ? (
                      <div>
                        <label htmlFor="givenname">First name</label>
                        <input
                          type="text"
                          name="givenname"
                          id="givenname"
                          autoComplete="given-name"
                          required
                          defaultValue={this.state.personData.givenname}
                          onFocus={this.resetForm}
                          onInput={this.updateGivenName}
                          ref={(c) => {
                            this.firstInput = c;
                          }}
                        />
                        <label htmlFor="email">E-mail</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="email"
                          required
                          defaultValue={this.state.personData.email}
                          onFocus={this.resetForm}
                          onInput={this.updateEmail}
                        />

                        <label htmlFor="country">Country</label>
                        <select
                          name="country"
                          value={this.state.subscriptionInfo.country}
                          onChange={this.updateCountry}
                        >
                          {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                          >
                        </select>

                        {needZipCode.includes(this.state.subscriptionInfo.country) ? (
                          <>
                            <label htmlFor="zipcode">ZIP/Postcode</label>
                            <input
                              type="zipcode"
                              name="zipcode"
                              id="zipcode"
                              required
                              onFocus={this.resetForm}
                              onInput={this.updateZipCode}
                            />
                          </>
                        ) : null}

                        <label className={styles.topMargin} htmlFor="separatebilling">
                          <input
                            type="checkbox"
                            name="separatebilling"
                            id="separatebilling"
                            onChange={this.setSeparateBilling}
                          />{' '}
                          I want to use a separate billing e-mail
                        </label>

                        {this.state.separateBilling ? (
                          <>
                            <label htmlFor="billingemail">Billing e-mail</label>
                            <input
                              type="email"
                              defaultValue=""
                              name="billingemail"
                              id="billingemail"
                              autoComplete="email"
                              onFocus={this.resetForm}
                            />
                          </>
                        ) : null}

                        {status === 'error' ? (
                          <div className={styles.error} dangerouslySetInnerHTML={{ __html: message }} />
                        ) : (
                          <p />
                        )}

                        <button type="submit">
                          <span>Choose Payment Method</span>
                        </button>
                        <small>
                          <em>You can add VAT and company info in the next step.</em>
                        </small>
                      </div>
                    ) : null}
                  </>
                )}

                <div className={styles.checkoutContainer}>
                  <div
                    className={[
                      'checkout-container',
                      styles.paddleArea,
                      this.state.showTrialMessage || this.state.showForm ? styles.hidden : '',
                    ].join(' ')}
                  />

                  <div className={styles.errorWrap} hidden ref={(c) => (this.error = c)}>
                    {/* <h3>Yikes</h3>
                    <p>
                      It looks like the Paddle (our payment provider) checkout form crashed. That sucks and Paddle
                      doesn't know how to fix it. I'm sorry about that. 😞
                    </p>
                    <p>Usually it works again when you open this page in incognito mode or a different browser. 🙏</p>
                    <p> - Kilian</p> */}
                  </div>
                </div>

                <button
                  type="button"
                  aria-label="close modal"
                  className={styles.close}
                  onClick={this.hideForm}
                  data-tip="Close"
                >
                  <Close width={16} height={16} id="" />
                </button>
              </form>
            </div>
            <SignUpCanceled
              visible={this.state.closedCallbackVisible}
              setVisible={this.setClosedCallbackVisible}
              restartCheckout={this.restartCheckout}
              closeForm={this.closeForm}
            />
          </>
        )}
      />
    );
  }
}

export default signUp;

const detailsQuery = graphql`
  query signUpQuery {
    iconSVG: file(relativePath: { eq: "images/icon.png" }) {
      publicURL
    }
  }
`;

const countries = [
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AX',
    name: 'Aland Islands',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BO',
    name: 'Bolivia',
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'IO',
    name: 'Brit. Indian Ocean',
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
  },
  {
    code: 'BN',
    name: 'Brunei',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'CC',
    name: 'Cocos Islands',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    name: 'Cote D’Ivoire',
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CW',
    name: 'Curaçao',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'TF',
    name: 'French Southern Terr.',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HM',
    name: 'Heard/ Mcdonald Islands',
  },
  {
    code: 'VA',
    name: 'Holy See/ Vatican City',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'XK',
    name: 'Kosovo',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    name: 'Lao People’s DR',
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    name: 'Macao',
  },
  {
    code: 'MK',
    name: 'Macedonia',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'FM',
    name: 'Micronesia',
  },
  {
    code: 'MD',
    name: 'Moldova',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'AN',
    name: 'Netherlands Antilles',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestinian Territory',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'CG',
    name: 'Republic of Congo',
  },
  {
    code: 'RS',
    name: 'Republic of Serbia',
  },
  {
    code: 'RE',
    name: 'Reunion',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'RU',
    name: 'Russian Federation',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'GS',
    name: 'S. Georgia/ Sandwich Islands',
  },
  {
    code: 'SH',
    name: 'Saint Helena',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    name: 'Saint Martin',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    name: 'Saint Vincent/ Grenadines',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'KR',
    name: 'South Korea',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SZ',
    name: 'Swaziland',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'TW',
    name: 'Taiwan',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'UM',
    name: 'United States (M.O.I.)',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VN',
    name: 'Vietnam',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
];

const needZipCode = ['AU', 'CA', 'FR', 'DE', 'IN', 'IT', 'NL', 'ES', 'GB', 'US'];
