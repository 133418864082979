import { css } from 'astroturf';
import React, { Component } from 'react';

const styles = css`
  .wrap {
    @media (min-width: 30rem) and (min-height: 45rem) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .visible {
    opacity: 1;
  }
  .contactForm {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    text-align: left;
    padding: 2rem 0 0;

    & > div {
      position: relative;
      width: 100%;
      margin: 0 auto;

      & div {
        color: red;
        margin-top: 1rem;
        font-weight: bold;
      }
    }
    & > div > * {
      display: block;
    }

    & label {
      display: block;
      line-height: 1;

      & span {
        line-height: 1;
        opacity: 0.75;
        font-size: 80%;
      }
    }

    & textarea {
      font-size: 1rem;
      padding: 1rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 70, 150, 0.4);
      width: 100%;
      display: block;
      margin: 0;
      left: 0;
      height: 5rem;
      margin-top: 1rem;
    }

    & div button {
      margin-top: 0.5rem;
      float: right;
      clear: right;
      border: 0;
      border-radius: 0.25rem;
      padding: 0 1rem;
      line-height: 2rem;
      background: #107db5;
      font-weight: 600;
      color: #fff;
      transition: 0.25s ease-in-out background;

      &:hocus {
        background: #67b26f;
      }
    }
  }
`;

class Page extends Component {
  constructor() {
    super();

    this.state = {
      mailSent: false,
      message: '',
      status: '',
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    const whynot = e.target.elements[0].value;
    const link = 'https://hooks.zapier.com/hooks/catch/2447005/7twomm/';
    const data = {
      whynot,
    };
    const result = await fetch(link, { method: 'POST', body: JSON.stringify(data) });

    if (result.ok) {
      this.setState({
        mailSent: true,
        status: 'ok',
        message: `<h3>Thank you for your feedback</h3>`,
      });
    } else {
      this.setState({
        mailSent: false,
        status: 'error',
        message: `<h3>Request not sent!</h3><p>There was an error sending this form. please try again later.</p>`,
      });
    }
  };

  resetForm = () => {
    this.setState({
      mailSent: false,
      status: '',
      message: '',
    });
  };

  hideForm = () => {
    this.props.setVisible(false);

    setTimeout(() => {
      this.resetForm();
    }, 500);
  };

  preventPropagation = e => {
    e.stopPropagation();
  };

  render() {
    const { mailSent, status, message } = this.state;

    return (
      <div className={[styles.wrap, styles.visible].join(' ')}>
        <form className={styles.contactForm} onSubmit={this.handleSubmit}>
          {mailSent && status === 'ok' && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {!mailSent && (
            <>
              <label htmlFor="whynot">
                If you changed your mind, can you tell us why?
                <br /> <span>Responses are anonymous.</span>
              </label>
              <div>
                <textarea name="whynot" id="whynot" onFocus={this.resetForm} />

                {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}

                <button type="submit">Let us know</button>
              </div>
            </>
          )}
        </form>
      </div>
    );
  }
}

export default Page;
