import { css } from 'astroturf';
import React, { Component } from 'react';
import { Alert, Close } from 'react-bytesize-icons';
import ExitQuestionaire from './ExitQuestionaire';

const styles = css`
  .wrap {
    position: fixed;
    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    z-index: 100;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s ease-in-out all;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .visible {
    opacity: 1;
    pointer-events: all;
  }
  .contactForm {
    position: relative;
    background: #fff;
    padding: 2rem;
    max-width: 30rem;
    width: 100%;
    height: 100%;
    max-width: 30rem;
    box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, 0 1px 10px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.06);

    @media (min-height: 50rem) {
      padding-top: 4rem;
    }

    & .formTitle {
      display: flex;
      align-items: center;

      & div:first-child {
        margin-right: 1rem;
        display: none;
        max-width: 48px;
        @media screen and (min-width: 50rem) {
          display: block;
        }
      }

      & p {
        font-size: 1rem;
        margin: 0.25rem 0 0;
        font-weight: normal;
      }
    }

    & h3,
    & legend {
      font-size: 1.25rem;
      color: #107db5;
      font-weight: 600;
      width: 100%;
      line-height: 1;
      margin: 0;
      padding: 0;

      & small {
        display: block;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5;
      }
    }
    & p {
      margin: 1rem 1rem 1.5rem;
      font-size: 80%;
      line-height: 1.5;

      & svg {
        line-height: 1;
        color: #67b26f;
      }
    }

    & div.buttonBlock {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #ddd;
      padding-bottom: 2rem;
      text-align: center;
      gap: 1rem;

      & small {
        margin-top: 1rem;
      }
    }

    & div.buttonBlock button {
      position: relative;
      background: linear-gradient(135deg, #67b26f, #67b26f);
      text-align: center;
      font-weight: bold;
      font-size: 1rem;
      color: #fff;
      border: 0;
      border-radius: 0.25rem;
      padding: 0 1rem;
      height: 2.5rem;
      transition: background 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;
      flex: 1 1 50%;
      white-space: nowrap;

      &:nth-child(2) {
        color: inherit;
        background: none;
        flex: 0 1 100px;
        &:hocus {
          text-decoration: underline;
          &::before {
            opacity: 0;
          }
        }
      }

      &:hocus {
        &::before {
          opacity: 1;
        }
      }

      & span {
        position: relative;
        z-index: 1;
      }

      & svg {
        vertical-align: middle;
        margin-right: 0.25rem;
      }
      &::before {
        content: ' ';
        display: block;
        background: linear-gradient(135deg, #4ca2cd, #67b26f);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: 0;
    color: #107db5;
    padding: 1rem;
    opacity: 0.5;
    transition: 0.25s ease-in-out opacity;

    &:hocus {
      opacity: 1;
    }
  }
`;

class Page extends Component {
  hideForm = () => {
    this.props.setVisible(false);
    this.props.closeForm();
  };

  restartCheckout = () => {
    this.props.setVisible(false);
    this.props.restartCheckout();
  };

  preventPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible } = this.props;

    return (
      <div className={[styles.wrap, visible && styles.visible].join(' ')} onClick={this.hideForm} aria-hidden={!visible}>
        <div className={styles.contactForm} onClick={this.preventPropagation}>
          <div className={styles.formTitle}>
            <div className={styles.icon}>
              <Alert width={48} height={48} color="#107db5" />
            </div>
            <div>
              <h3>You're closing the signup process</h3>
              <p>You have not yet finished signing up.</p>
            </div>
          </div>

          <div className={styles.buttonBlock}>
            <button type="button" onClick={this.restartCheckout}>
              <span>Return to signup process</span>
            </button>

            <button type="button" onClick={this.hideForm}>
              <span>Close</span>
            </button>
            <small>
              <a href="mailto:support@polypane.app?subject=issue with sign up">Let us know</a> if something went wrong.
            </small>
          </div>

          <ExitQuestionaire />

          <button type="button" aria-label="close modal" className={styles.close} onClick={this.hideForm} data-tip="Close">
            <Close width={16} height={16} />
          </button>
        </div>
      </div>
    );
  }
}

export default Page;
